<template>
  <div class="createAccount-container pt-5">
    <div class="desc py-4 dk:px-5 mb:px-2">
      <div><span class="mark-important">*</span> All fields marked with an asterisk [<span class="mark-important">*</span>] should be completed.
      </div>
      <div><span class="mark-important">*</span> All fields should be written in English Only.</div>
    </div>
    <div class="account-content dk:px-5 mb:px-2">
      <a-form-model ref="registForm" :model="form" :rules="rules" layout="vertical">
        <a-form-model-item label="Country" prop="country">
          <search-select :options="countries" v-model="form.country" :disabled="form.country && form.country.length>0" :placeholder="`Please select your country`"></search-select>
        </a-form-model-item>
        <a-form-model-item :label="`${isChina ? '邮箱':'E-mail'}`" prop="eMail">
          <a-input v-model="form.eMail"  :disabled="form.eMail && form.eMail.length>0" placeholder="Please input your User ID (E-mail)"/>
        </a-form-model-item>
        <a-form-model-item :label="`${isChina ? '密码':'Password'}`" prop="password">
          <a-input v-model="form.password" type="password" placeholder="Please input your Password"/>
        </a-form-model-item>
        <a-form-model-item :label="`${isChina ? '确认密码':'Confirm Password'}`" prop="confirmPassword">
          <a-input v-model="form.confirmPassword" type="password" placeholder="Please input your Confirm Password"/>
        </a-form-model-item>
        <a-form-model-item v-if="!isChina" label="First Name" prop="firstName">
          <a-input v-model="form.firstName" :disabled="form.firstName && form.firstName.length>0" placeholder="Please input your First Name"/>
        </a-form-model-item>
        <a-form-model-item v-if="!isChina" label="Last Name" prop="lastName">
          <a-input v-model="form.lastName" :disabled="form.lastName && form.lastName.length>0" placeholder="Please input your Last Name"/>
        </a-form-model-item>
        <a-form-model-item v-if="isChina" label="姓名" prop="realName">
          <a-input v-model="form.realName" placeholder="Please input your Name"/>
        </a-form-model-item>
        <a-form-model-item label="Year of Birth">
          <a-select v-model="form.birth" placeholder="Please Select Year">
            <a-select-option value="">
              Select Year
            </a-select-option>
            <a-select-option v-for="(item, index) in years" :value="item" :key="index">
              {{item}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item class="ext-item" label="Title" prop="title">
          <a-radio-group name="title" v-model="form.title">
            <a-radio class="form-label" v-for="(item, index) in titles" :value="item" :key="index">
              {{item}}
          </a-radio>
          </a-radio-group>
          <a-input class="ext-input" v-if="form.title === 'Other'" v-model="form.titleExt" placeholder="Please input"/>
        </a-form-model-item>
        <a-form-model-item class="ext-item" label="Degree" prop="degree">
          <a-radio-group name="degree" v-model="form.degree">
            <a-radio class="form-label" v-for="(item, index) in degrees" :value="item" :key="index">
              {{item}}
            </a-radio>
          </a-radio-group>
          <a-input class="ext-input" v-if="form.degree === 'Other'" v-model="form.degreeExt" placeholder="Please input"/>
        </a-form-model-item>
        <a-form-model-item class="ext-item" label="Category" prop="category">
          <a-radio-group name="category" v-model="form.category">
            <a-radio class="form-label" v-for="(item, index) in categories" :value="item" :key="index">
              {{item}}
            </a-radio>
          </a-radio-group>
          <a-input class="ext-input" v-if="form.category === 'Other'" v-model="form.categoryExt" placeholder="Please input"/>
        </a-form-model-item>
        <a-form-model-item :label="`${isChina ? '单位':'Affiliation'}`" prop="affiliation">
          <a-input v-model="form.affiliation" placeholder="Eg. OOO Hospital, Institute, University"/>
        </a-form-model-item>
        <a-form-model-item class="ext-item" label="Department" prop="department">
          <a-input class="dk:w-2/3 mb:w-full department-input " v-if="form.departmentOtherStatus" v-model="form.department" placeholder="Please input"/>
          <search-select class="dk:w-2/3 mb:w-full" v-else :disabled="form.departmentOtherStatus" :options="department" placeholder="Selected Department" v-model="form.department"></search-select>
          <a-checkbox class="department-checkbox" v-model="form.departmentOtherStatus">
            Other
          </a-checkbox>
        </a-form-model-item>
        <a-form-model-item class="ext-item" label="Specialty">
          <a-radio-group name="specialty" v-model="form.specialty">
            <a-radio class="form-label" v-for="(item, index) in specialties" :value="item" :key="index">
              {{item}}
            </a-radio>
          </a-radio-group>
          <a-input style="margin-top: 15px" v-if="form.specialty === 'Other'" v-model="form.specialtyExt" placeholder="Please input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="Mobile" prop="mobile">
          <a-input v-model="form.mobile">
            <a-select :disabled="true" slot="addonBefore" :value="mobileCode" style="width: 80px">
              <a-select-option v-for="(item, index) in countriesCode" :value="item.dialCode" :key="index">
                {{item.dialCode}}
              </a-select-option>
            </a-select>
          </a-input>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="card-footer text-center py-5 dk:px-32">
      <div class="grid grid-cols-2 gap-5 mt-2">
        <div class="cancel">
          <a-button class="w-full h-11 rounded" type="button" @click="$router.back()" :disabled="submitLoading">Cancel</a-button>
        </div>
        <div class="confirm">
          <a-button class="w-full h-11 rounded" type="button" @click="confirm" :loading="submitLoading">Confirm</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {ErrorCode} from "@/types/ErrorCode";

  const titles = ['Prof.', 'Dr.', 'Mr.', 'Ms.', 'Other'];
  const degrees = ['M.D.', 'Ph.D.', 'M.D.,Ph.D.', 'Other'];
  const categories = ['Physician', 'Trainee/Resident', 'Nurse', 'Company Personnel', 'Other'];
  const specialties = ['Adult Cardiovascular', 'Congenital Cardiovascular', 'Peripheral Vascular', 'Thoracic', 'Other'];
  import { countriesCode } from "../../../assets/js/country/countryCode";
  import { departments } from "@/assets/js/department"
  import {countries} from "../../../assets/js/country/country";
  import {modifyUserInfo} from "@/api/user";
  import {formModalWarnAndScroll, getMemberInfo, setMemberInfo} from "@/utils/util";

  export default {
    name: "ModifyInfoForm",
    components: {
      SearchSelect: () => import('@/components/SearchSelect')
    },
    data() {
      const validatePasswordConfirm = (rule, value, callback) => {
        if (value === '') {
          callback(new Error());
        } else if (value !== this.form.password) {
          callback(new Error());
        } else {
          callback();
        }
      };
      const validateDepartment = (rule, value, callback) => {
        if (!value) {
          if (!this.form.departmentOtherStatus) {
            callback(new Error("Please select department"));
          }else {
            callback(new Error("Please enter your department."));
          }
        } else {
          callback();
        }
      };
      const validateEmail = (rule, value, callback) => {
        if (!value) {
          callback(new Error());
        } else if (!(/^[A-z0-9]+([-._][A-z0-9]+)*@([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,4}$/.test( value ) && /^(?=.{1,64}@.{4,64}$)(?=.{6,100}$).*/.test( value ))) {
          callback(new Error());
        } else {
          callback();
        }
      };
      return {
        form: {
          id: null,
          country: undefined,
          eMail: '',
          password: '',
          confirmPassword: '',
          firstName: '',
          lastName: '',
          birth: '',
          title: '',
          titleExt: '',
          degree: '',
          degreeExt: '',
          category: '',
          categoryExt: '',
          affiliation: '',
          department: undefined,
          departmentOtherStatus: false,
          specialty: '',
          specialtyExt: '',
          mobile: '',
          realName: ''
        },
        rules: {
          country: [
            { required: true, message: 'Please select country.', trigger: 'change' },
          ],
          eMail: [
            { required: true, validator: validateEmail, message: 'Please enter your email address.', trigger: 'blur' },
          ],
          password: [
            { required: true, message: 'Please enter your password.', trigger: 'blur' },
          ],
          confirmPassword: [
            { required: true, validator: validatePasswordConfirm, message: 'Password is not correct.', trigger: 'blur' },
          ],
          firstName: [
            { required: true, message: 'Please enter your first name.', trigger: 'blur' },
          ],
          lastName: [
            { required: true, message: 'Please enter your last name.', trigger: 'blur' },
          ],
          realName: [
            { required: true, message: 'Please enter your name.', trigger: 'blur' },
          ],
          title: [
            { required: true, message: 'Please select your title.', trigger: 'change' },
          ],
          degree: [
            { required: true, message: 'Please select your degree.', trigger: 'change' },
          ],
          category: [
            { required: true, message: 'Please select your category.', trigger: 'change' },
          ],
          affiliation: [
            { required: true, message: 'Please enter your affiliation.', trigger: 'blur' },
          ],
          department: [
            { required: true, validator: validateDepartment, trigger: 'blur' },
            { validator: validateDepartment, trigger: 'change' },
          ],
          mobile: [
            { required: true, message: 'Please enter your Mobile.', trigger: 'blur' },
          ],
        },
        mobileCode: '+86',
        submitLoading: false,
        years: [],
        titles,
        degrees,
        countries,
        categories,
        specialties,
        countriesCode,
        department: []
      }
    },
    watch: {
      'form.departmentOtherStatus': {
        handler() {
            this.form.department = undefined
            this.$set(this.form, 'department', undefined)
        }
      },
      'form.country': {
        handler(val) {
          if (val) {
            this.countriesCode.forEach((item)=> {
              if (item.name === val) {
                console.log(item.name, val, item.dialCode,  item.name === val)
                this.mobileCode = "+" + item.dialCode
              }
            })
          } else {
            this.mobileCode = "+86"
          }
        }
      }
    },
    created() {
      this.initDepartment();
      this.initYears()
      this.initUserInfo();
    },
    methods: {
      initUserInfo() {
        const userInfo = getMemberInfo();
        let title = ""
        let titleExt = ""
        if (userInfo && userInfo.title) {
          if (this.titles.indexOf(userInfo.title) > -1) {
            title = userInfo.title
          }else{
            title = "Other"
            titleExt = userInfo.title
          }
        }
        let degree = ""
        let degreeExt = ""
        if (userInfo && userInfo.degree) {
          if (this.degrees.indexOf(userInfo.degree) > -1) {
            degree = userInfo.degree
          }else{
            degree = "Other"
            degreeExt = userInfo.degree
          }
        }
        let category = ""
        let categoryExt = ""
        if (userInfo && userInfo.category) {
          if (this.categories.indexOf(userInfo.category) > -1) {
            category = userInfo.category
          }else{
            category = "Other"
            categoryExt = userInfo.category
          }
        }
        let specialty = ""
        let specialtyExt = ""
        if (userInfo && userInfo.specialty) {
          if (this.titles.indexOf(userInfo.specialty) > -1) {
            specialty = userInfo.specialty
          }else{
            specialty = "Other"
            specialtyExt = userInfo.specialty
          }
        }
        this.form = {
          id: userInfo.id,
          country: userInfo.country || undefined,
          eMail: userInfo.email || '',
          password: '',
          confirmPassword: '',
          firstName: userInfo.firstname || '',
          lastName: userInfo.familyname || '',
          realName: userInfo.realname,
          birth: userInfo.birth || '',
          title: title,
          titleExt: titleExt,
          degree: degree,
          degreeExt: degreeExt,
          category: category,
          categoryExt: categoryExt,
          affiliation: userInfo.site || '',
          department: userInfo.department || undefined,
          departmentOtherStatus: this.department.indexOf(userInfo.department) === -1,
          specialty: specialty,
          specialtyExt: specialtyExt,
          mobile: userInfo.mobile || ''
        }
      },
      initDepartment(){
        this.department = departments.map((item)=>{
          return item.eng;
        })
      },
      initYears() {
        const currentYear = new Date().getFullYear();
        for (let i = 1950; i <= currentYear - 18; i++) {
          this.years.push(`${i}`)
        }
      },
      confirm() {
        this.$refs.registForm.validate(valid => {
          if (!valid) {
            this.$nextTick(()=>{
              formModalWarnAndScroll(this.$refs.registForm);
            })
            return;
          }
          this.submitLoading = true
          const formData = {
            id: this.form.id,
            site: this.form.affiliation,
            birth: this.form.birth,
            category: this.form.categoryExt || this.form.category,
            country: this.form.country,
            degree: this.form.degreeExt || this.form.degree,
            department: this.form.department,
            email: this.form.eMail,
            firstname: this.form.firstName,
            familyname: this.form.lastName,
            mobile: this.form.mobile,
            password: this.form.password,
            specialty: this.form.specialtyExt || this.form.specialty,
            title: this.form.titleExt || this.form.title,
            realname: this.form.realName || this.form.firstName + " " + this.form.lastName
          }
          modifyUserInfo(formData).then((res)=> {
            if (res.code === 0 ) {
              setMemberInfo(res.data)
              this.$router.push({name: "myPage"})
            } else if(res.code === ErrorCode.MOBILE_EXISTS.value){
              this.$message.error(ErrorCode.MOBILE_EXISTS.messageEn)
            } else if(res.code === ErrorCode.MISS_PARAMS.value){
              this.$message.error(ErrorCode.MISS_PARAMS.messageEn)
            }
          }).catch(()=>{
            // this.$message.error(err.msg)
          }).finally(()=>{
            this.submitLoading = false
          })
        });
      }
    },
    computed: {
      isChina() {
        return false // this.form.country === "China (中国)"
      }
    }
  }
</script>

<style lang="less" scoped>
  .createAccount-container {
    .desc {
      & > div {
        font-family: Akrobat;
        font-weight: 600;
        color: #333333;
        font-size: 13px;
        line-height: 20px;
      }
      .mark-important {
        color: red;
      }
    }
    /deep/ .ant-form-item-label {
      label {
        font-family: Akrobat;
        font-weight: 600;
        color: #333333;
        font-size: 15px;
        line-height: 20px;
      }
    }
    .form-label {
      font-family: Akrobat;
      font-weight: 500;
      color: #333333;
      font-size: 14px;
      line-height: 20px;
    }
    .tips {
      color: #EA5350;
    }
    @media screen and (min-width: 768px) {
      .ext-item {
        /deep/ .ant-form-item-children {
          display: flex;
          align-items: center;
        }
        .department-checkbox {
          margin-left: 20px;
          min-width: 80px;
        }
        .ext-input {
          max-width: 200px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      .ext-item {
        /deep/ .ant-form-item-children {
          .ant-radio-wrapper {
            margin-bottom: 8px;
          }
        }
        .department-checkbox {
          margin-top: 10px;
          line-height: 20px;
          /*display: flex;*/
          /*align-items: center;*/
        }
        .department-input {
          margin-top: 10px;
        }
        .ext-input {
          margin-top: 5px;
        }
      }
    }
    .card-footer {
      .cancel {
        button {
          background: rgb(222, 226, 230);
          color: #333333;
          font-family: Akrobat;
          font-weight: 600;
          &:hover {
            background: rgba(222, 226, 230, 0.8);
          }
        }
      }

      .confirm {
        button {
          background: rgb(248, 179, 1);
          color: #333333;
          font-family: Akrobat;
          font-weight: 600;
          &:hover {
            background: rgba(248, 179, 1, 0.8)
          }
        }
      }
      background: #F5F7FA;
    }
  }
</style>
