import request from '@/utils/request'

const userApi = {
    getUserInfoUrl: '/api/sysMeeting/userInfo',
    signUpUrl: '/api/sysMeeting/signUp',
    loginUrl: '/api/sysMeeting/t/login',
    modifyUrl: '/api/sysMeeting/modify',
}

/**
 * 注册
 * @param params
 * @returns {*}
 */
export function signUp(params) {
    return request({
        url: userApi.signUpUrl,
        method: 'post',
        data: params
    })
}

/**
 * 获取用户信息
 * @param params
 * @returns {*}
 */
export function getUserInfo(params) {
    return request({
        url: userApi.getUserInfoUrl,
        method: 'get',
        params
    })
}

/**
 * 登录
 * @param params
 * @returns {*}
 */
export function login(params) {
    return request({
        url: userApi.loginUrl,
        method: 'post',
        data: params
    })
}

/**
 * 更新用户信息
 * @param params
 * @returns {*}
 */
export function modifyUserInfo(params) {
    return request({
        url: userApi.modifyUrl,
        method: 'post',
        data: params
    })
}
