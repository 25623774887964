export const departments = [
    {
        "eng": "Cardiovascular and Thoracic Surgery",
        "cn": "心胸外科"
    },
    {
        "eng": "Pediatrics-Cardiology",
        "cn": "儿科心脏病学"
    },
    {
        "eng": "Anatomy",
        "cn": "解剖学"
    },
    {
        "eng": "Anesthesiology",
        "cn": "麻醉学"
    },
    {
        "eng": "Biochemistry & Molecular Biology",
        "cn": "생화학 및 분자생물학"
    },
    {
        "eng": "Biostatistics",
        "cn": "의학통계학"
    },
    {
        "eng": "Dermatology",
        "cn": "피부과"
    },
    {
        "eng": "Emergency Medicine",
        "cn": "응급의학과"
    },
    {
        "eng": "Environmental Medical Biology",
        "cn": "환경의생물학"
    },
    {
        "eng": "Family Medicine",
        "cn": "가정의학과"
    },
    {
        "eng": "Internal Medicine",
        "cn": "내과"
    },
    {
        "eng": "Internal Medicine-Cardiology",
        "cn": "내과-심장내과"
    },
    {
        "eng": "Internal Medicine-Endocrine",
        "cn": "내과-내분비"
    },
    {
        "eng": "Internal Medicine-G-I/Hepatology",
        "cn": "내과-소화기"
    },
    {
        "eng": "Internal Medicine-Infection",
        "cn": "내과-감염"
    },
    {
        "eng": "Internal Medicine-Nephrology",
        "cn": "내과-신장"
    },
    {
        "eng": "Internal Medicine-Pulomary",
        "cn": "내과-호흡기"
    },
    {
        "eng": "Laboratory Medicine",
        "cn": "진단검사의학과"
    },
    {
        "eng": "Medical Engineering",
        "cn": "의학공학과"
    },
    {
        "eng": "Microbiology",
        "cn": "미생물학"
    },
    {
        "eng": "Neurology",
        "cn": "신경과"
    },
    {
        "eng": "Neuropsychiatry",
        "cn": "신경정신과"
    },
    {
        "eng": "Neurosurgery",
        "cn": "신경외과"
    },
    {
        "eng": "Nuclear Medicine",
        "cn": "핵의학과"
    },
    {
        "eng": "Obstetrics and Gynecology",
        "cn": "산부인과"
    },
    {
        "eng": "Occupational and Environmental Medicine",
        "cn": "산업의학과"
    },
    {
        "eng": "Ophthalmology",
        "cn": "안과"
    },
    {
        "eng": "Orthopedic Sergery",
        "cn": "정형외과"
    },
    {
        "eng": "Otorhinolaryngology",
        "cn": "이비인후과"
    },
    {
        "eng": "Pathology",
        "cn": "병리과"
    },
    {
        "eng": "Pediatrics",
        "cn": "소아청소년과"
    },
    {
        "eng": "Pediatrics-Endocrine",
        "cn": "소아청소년과-내분비"
    },
    {
        "eng": "Pediatrics-G-I/Hepatology",
        "cn": "소아청소년과-소화기"
    },
    {
        "eng": "Pediatrics-Infection",
        "cn": "소아청소년과-감염"
    },
    {
        "eng": "Pediatrics-Nephrology",
        "cn": "소아청소년과-신장"
    },
    {
        "eng": "Pediatrics-Pulomary",
        "cn": "소아청소년과-호흡기"
    },
    {
        "eng": "Pharmacology",
        "cn": "약리학"
    },
    {
        "eng": "Physics",
        "cn": "물리학과"
    },
    {
        "eng": "Physiology",
        "cn": "생리학"
    },
    {
        "eng": "Plastic and Reconstructive Surgery",
        "cn": "성형외과"
    },
    {
        "eng": "Preventive Medicine",
        "cn": "예방의학과"
    },
    {
        "eng": "Psychiatry",
        "cn": "정신건강의학과"
    },
    {
        "eng": "Radiology",
        "cn": "영상의학과"
    },
    {
        "eng": "Rehabilitation Medicine",
        "cn": "재활의학과"
    },
    {
        "eng": "Surgery",
        "cn": "외과"
    },
    {
        "eng": "Surgery-Hepatobiliary",
        "cn": "외과-간담췌"
    },
    {
        "eng": "Surgery-Pediatric",
        "cn": "외과-소아"
    },
    {
        "eng": "Surgery-Transplantation",
        "cn": "외과-이식"
    },
    {
        "eng": "Therapeutic Radiology and Oncology",
        "cn": "방사선종양학과"
    },
    {
        "eng": "Urology",
        "cn": "泌尿外科"
    },
    {
        "eng": "Others",
        "cn": "其它"
    }
]
