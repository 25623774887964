export const ErrorCode = {
    LOGIN_FAIL: { value: 10052, messageCn: "登录失败", messageEn: "Login failed."},
    PASSWORD_ERROR: {value: 10053, messageCn: "用户密码错误", messageEn: "User authentication failed."},
    USER_NOT_EXISTS: { value: 10055, messageCn: "用户不存在", messageEn: "User authentication failed." },
    MOBILE_EXISTS: { value: 10061, messageCn: "手机号已被使用", messageEn: "This mobile is already registered on the website." },
    USERNAME_EXISTS: { value: 10062, messageCn: "用户已存在", messageEn: "This email is already registered on the website. Please try the login." },
    MISS_PARAMS: { value: 10064, messageCn: "参数缺失", messageEn: "Missing params." },
    REGISTER_FAIL: { value: 10066, messageCn: "注册失败", messageEn: "SignUp failed." }

}
